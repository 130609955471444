/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-app:not(:has(app-login)) {
  max-width: 1024px;
  margin: 0 auto;
}

h5 {
  font-size: 0.95rem !important;
  font-weight: 500 !important;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

app-messenger {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

ion-header {
  ion-toolbar {
    ion-avatar {
      height: 35px;
      width: 35px;
    }

    ion-back-button {
      height: auto;
      min-height: auto;
    }

    ion-title {
      line-height: 0.9;
      span {
        line-height: 1;
        font-size: 0.8rem;
        font-weight: normal;
      }
    }

    // Badge for hambuger menu button
    ion-buttons {
      position: relative;

      ion-badge {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 0.65rem;
      }
    }
  }
}

.level-0 {
  background: var(--ion-color-range-0);
  color: #fff;
}
.level-1 {
  background: var(--ion-color-range-1);
  color: #fff;
}
.level-2 {
  background: var(--ion-color-range-2);
  color: #000;
}
.level-3 {
  background: var(--ion-color-range-3);
  color: #000;
}
.level-4 {
  background: var(--ion-color-range-4);
  color: #fff;
}
.level-5 {
  background: var(--ion-color-range-5);
  color: #fff;
}

.app-action-sheet {
  .action-sheet-title {
    background-color: var(--ion-color-tertiary);
    text-align: left;
    font-size: 1rem;
    color: var(--ion-color-dark);
  }
  .action-sheet-button-inner {
    justify-content: start;
  }

  .has-badge .action-sheet-button-inner:after {
    position: absolute;
    right: 10px;
    padding: 3px 4px 4px 4px;
    display: inline-block;
    background-color: var(--ion-color-danger);
    border-radius: 4px;
    font-size: 13px;
    color: white;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    contain: content;
    vertical-align: baseline;
  }

  @for $i from 1 through 9 {
    .badge-#{$i} .action-sheet-button-inner:after {
      content: "#{$i}";
    }
  }

  .badge-10 .action-sheet-button-inner:after {
    content: "9+";
  }

  .action-sheet-cancel {
    color: var(--ion-color-danger);
    .action-sheet-icon {
      color: var(--ion-color-danger);
    }
  }
}

ion-list.menu {
  ion-list-header {
    font-size: 1.2rem;
    font-weight: bold;
    background-color: white;
  }
  ion-item-group {
    ion-item-divider {
      border: none;
      ion-label {
        font-size: 1rem !important;
      }
    }
    ion-item {
      --min-height: 36px;
      padding-left: 5vw;
      ion-label {
        margin: 0 !important;
        font-size: 0.9rem !important;
        font-weight: normal;
      }
    }
  }
  ion-item {
    ion-label {
      font-weight: bold;
    }
  }
  ion-item.selected {
    ion-label {
      --color: var(--ion-color-secondary);
    }
    --background: var(--ion-color-tertiary);
  }
}

.progression-bar {
  display: flex;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    span {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
  div:not(:last-child) {
    border-right: 1px solid white;
  }
}

@media (min-width: 768px) {
  ion-popover {
    --offset-x: calc(-1 * ((100vw - 768px) / 2));
  }
}
