// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #253369;
  --ion-color-primary-rgb: 37, 51, 105;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #212d5c;
  --ion-color-primary-tint: #3b4778;

  --ion-color-secondary: #0d6cb4;
  --ion-color-secondary-rgb: 13, 108, 180;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0b5f9e;
  --ion-color-secondary-tint: #257bbc;

  --ion-color-tertiary: #e6f3ff;
  --ion-color-tertiary-rgb: 230, 243, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #cad6e0;
  --ion-color-tertiary-tint: #e9f4ff;

  --ion-color-alumni: #4ab081;
  --ion-color-alumni-rgb: 74, 176, 129;
  --ion-color-alumni-contrast: #000000;
  --ion-color-alumni-contrast-rgb: 0, 0, 0;
  --ion-color-alumni-shade: #419b72;
  --ion-color-alumni-tint: #5cb88e;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** range 5 **/
  --ion-color-range-5: #1e9600;
  --ion-color-range-5-rgb: 30, 150, 0;
  --ion-color-range-5-contrast: #ffffff;
  --ion-color-range-5-contrast-rgb: 255, 255, 255;
  --ion-color-range-5-shade: #1a8400;
  --ion-color-range-5-tint: #35a11a;

  /** range 4 **/
  --ion-color-range-4: #76ba00;
  --ion-color-range-4-rgb: 118, 186, 0;
  --ion-color-range-4-contrast: #000000;
  --ion-color-range-4-contrast-rgb: 0, 0, 0;
  --ion-color-range-4-shade: #68a400;
  --ion-color-range-4-tint: #84c11a;

  /** range 3 **/
  --ion-color-range-3: #d0df00;
  --ion-color-range-3-rgb: 208, 223, 0;
  --ion-color-range-3-contrast: #000000;
  --ion-color-range-3-contrast-rgb: 0, 0, 0;
  --ion-color-range-3-shade: #b7c400;
  --ion-color-range-3-tint: #d5e21a;

  /** range 2 **/
  --ion-color-range-2: #ffc400;
  --ion-color-range-2-rgb: 255, 196, 0;
  --ion-color-range-2-contrast: #000000;
  --ion-color-range-2-contrast-rgb: 0, 0, 0;
  --ion-color-range-2-shade: #e0ac00;
  --ion-color-range-2-tint: #ffca1a;

  /** range 1 **/
  --ion-color-range-1: #ff6300;
  --ion-color-range-1-rgb: 255, 99, 0;
  --ion-color-range-1-contrast: #000000;
  --ion-color-range-1-contrast-rgb: 0, 0, 0;
  --ion-color-range-1-shade: #e05700;
  --ion-color-range-1-tint: #ff731a;

  /** range 0 **/
  --ion-color-range-0: #ff0000;
  --ion-color-range-0-rgb: 255, 0, 0;
  --ion-color-range-0-contrast: #ffffff;
  --ion-color-range-0-contrast-rgb: 255, 255, 255;
  --ion-color-range-0-shade: #e00000;
  --ion-color-range-0-tint: #ff1a1a;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #253369;
//     --ion-color-primary-rgb: 37, 51, 105;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #212d5c;
//     --ion-color-primary-tint: #3b4778;

//     --ion-color-secondary: #0d6cb4;
//     --ion-color-secondary-rgb: 13, 108, 180;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #0b5f9e;
//     --ion-color-secondary-tint: #257bbc;

//     --ion-color-tertiary: #e6f3ff;
//     --ion-color-tertiary-rgb: 230, 243, 255;
//     --ion-color-tertiary-contrast: #000000;
//     --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//     --ion-color-tertiary-shade: #cad6e0;
//     --ion-color-tertiary-tint: #e9f4ff;
//     // --ion-color-primary: #e27325;
//     // --ion-color-primary-rgb: 226, 115, 37;
//     // --ion-color-primary-contrast: #000000;
//     // --ion-color-primary-contrast-rgb: 0, 0, 0;
//     // --ion-color-primary-shade: #c76521;
//     // --ion-color-primary-tint: #e5813b;

//     // --ion-color-secondary: #af2b17;
//     // --ion-color-secondary-rgb: 175, 43, 23;
//     // --ion-color-secondary-contrast: #ffffff;
//     // --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     // --ion-color-secondary-shade: #9a2614;
//     // --ion-color-secondary-tint: #b7402e;

//     // --ion-color-tertiary: #ffee32;
//     // --ion-color-tertiary-rgb: 255, 238, 50;
//     // --ion-color-tertiary-contrast: #000000;
//     // --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//     // --ion-color-tertiary-shade: #e0d12c;
//     // --ion-color-tertiary-tint: #fff047;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;

//     /** range 5 **/
//     --ion-color-range-5: #1e9600;
//     --ion-color-range-5-rgb: 30, 150, 0;
//     --ion-color-range-5-contrast: #ffffff;
//     --ion-color-range-5-contrast-rgb: 255, 255, 255;
//     --ion-color-range-5-shade: #1a8400;
//     --ion-color-range-5-tint: #35a11a;

//     /** range 4 **/
//     --ion-color-range-4: #76ba00;
//     --ion-color-range-4-rgb: 118, 186, 0;
//     --ion-color-range-4-contrast: #000000;
//     --ion-color-range-4-contrast-rgb: 0, 0, 0;
//     --ion-color-range-4-shade: #68a400;
//     --ion-color-range-4-tint: #84c11a;

//     /** range 3 **/
//     --ion-color-range-3: #d0df00;
//     --ion-color-range-3-rgb: 208, 223, 0;
//     --ion-color-range-3-contrast: #000000;
//     --ion-color-range-3-contrast-rgb: 0, 0, 0;
//     --ion-color-range-3-shade: #b7c400;
//     --ion-color-range-3-tint: #d5e21a;

//     /** range 2 **/
//     --ion-color-range-2: #ffc400;
//     --ion-color-range-2-rgb: 255, 196, 0;
//     --ion-color-range-2-contrast: #000000;
//     --ion-color-range-2-contrast-rgb: 0, 0, 0;
//     --ion-color-range-2-shade: #e0ac00;
//     --ion-color-range-2-tint: #ffca1a;

//     /** range 1 **/
//     --ion-color-range-1: #ff6300;
//     --ion-color-range-1-rgb: 255, 99, 0;
//     --ion-color-range-1-contrast: #000000;
//     --ion-color-range-1-contrast-rgb: 0, 0, 0;
//     --ion-color-range-1-shade: #e05700;
//     --ion-color-range-1-tint: #ff731a;

//     /** range 0 **/
//     --ion-color-range-0: #ff0000;
//     --ion-color-range-0-rgb: 255, 0, 0;
//     --ion-color-range-0-contrast: #ffffff;
//     --ion-color-range-0-contrast-rgb: 255, 255, 255;
//     --ion-color-range-0-shade: #e00000;
//     --ion-color-range-0-tint: #ff1a1a;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
